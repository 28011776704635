import React, { createContext, useState, useContext } from 'react';

// Define the context
export const MnemonicContext = createContext();

// Define a provider component
export function MnemonicProvider({ children }) {
  const [wordValues, setWordValues] = useState(Array(12).fill(""));

  return (
    <MnemonicContext.Provider value={{ wordValues, setWordValues }}>
      {children}
    </MnemonicContext.Provider>
  );
}

// Define the context
export const PrivateKeyContext = createContext();

// Define a provider component
export function PrivateKeyProvider({ children }) {
  const [privateKeyHex, setPrivateKeyHex] = useState("");

  return (
    <PrivateKeyContext.Provider value={{ privateKeyHex, setPrivateKeyHex }}>
      {children}
    </PrivateKeyContext.Provider>
  );
}


// Define the context
export const EvmAddressContext = createContext();

// Define a provider component
export function EvmAddressProvider({ children }) {
  const [evmAddress, setEVMAddress] = useState("");

  return (
    <EvmAddressContext.Provider value={{ evmAddress, setEVMAddress }}>
      {children}
    </EvmAddressContext.Provider>
  );
}

// Define the context
export const HederaAccountIDContext = createContext();

// Define a provider component
export function HederaAccountIDProvider({ children }) {
  const [hederaAccountID, setHederaAccountID] = useState("");

  return (
    <HederaAccountIDContext.Provider value={{ hederaAccountID, setHederaAccountID }}>
      {children}
    </HederaAccountIDContext.Provider>
  );
}


// Define the context
export const HederaAccountBalanceHbarContext = createContext();

// Define a provider component
export function HederaAccountBalanceHbarProvider({ children }) {
  const [hederaAccountBalanceHbar, setHederaAccountBalanceHbar] = useState("");

  return (
    <HederaAccountBalanceHbarContext.Provider value={{ hederaAccountBalanceHbar, setHederaAccountBalanceHbar }}>
      {children}
    </HederaAccountBalanceHbarContext.Provider>
  );
}