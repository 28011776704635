import "./assets/App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PageCreateNewEvent from "./pages/PageCreateNewEvent";
import PageLogin from "./pages/PageLogin";
import PageProfiles from "./pages/PageProfiles";
import PageEvents from "./pages/PageEvents";

function App() {
  return (
    <Router>
      <Routes>
        {<Route path="/" element={<PageLogin />} />}
        {<Route path="/login" element={<PageLogin />} />}
        {<Route path="/profiles" element={<PageProfiles />} />}
        {<Route path="/events" element={<PageEvents />} />}
        {<Route path="/create-new-event" element={<PageCreateNewEvent />} />}
      </Routes>
    </Router>
  );
}
export default App;
