import React, { useContext, useEffect, useState } from "react";
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";

import {
  GenerateUpdateMnemonicPrivateKey,
  DeriveEVMAddress,
  DeriveHederaAccountDetails,
} from "./GenerateUpdateMnemonic";

import { HederaAccountIDContext, PrivateKeyContext } from "../contexts/contexts";
import { addProfile } from "../backend/LocalStorageProfiles";

const ImportProfileButtonModal = () => {
  const [showModal, setShowModal] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const { hederaAccountID, setHederaAccountID } = useContext(
    HederaAccountIDContext,
  );
  const { privateKeyHex, setPrivateKeyHex } = useContext(PrivateKeyContext);

  const [newProfileAlias, setNewProfileAlias] = useState("");

  const hederaAccountIdRegex = /^\d+\.\d+\.\d+$/;
  const privateKeyRegex = /^0x[a-fA-F0-9]{64}$/;



  const [profileType, setProfileType] = useState("Audience");

  const handleProfileTypeChange = (e) => {
    setProfileType(e.target.value);
  };


  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const handleSaveButtonClick = () => {
    setSaveButtonClicked(true);
  };

  useEffect(() => {
    const handleSaveProfile = () => {
      if (saveButtonClicked) {
        // Regular expressions for validation

        if (!privateKeyRegex.test(privateKeyHex)) {
          alert("No Valid Private Key or Mnemonic Detected.");
          return;
        }

        if (!hederaAccountIdRegex.test(hederaAccountID)) {
          alert("Hedera Account has not been initialized.");
          return;
        }

        // Validation checks
        if (!newProfileAlias.trim()) {
          alert("Profile alias cannot be empty");
          return;
        }

        const newProfile = {
          alias: newProfileAlias,
          hederaAccountID: hederaAccountID,
          profileType: profileType,
          privateKeyHex: privateKeyHex,
        };
        addProfile(newProfile)
          .then(() => {
            console.log("Profile added successfully");
            setPrivateKeyHex("");
            setNewProfileAlias("");
            setActiveStep(0);
            setShowModal(false);
          })
          .catch((error) => {
            console.error("Error in handleSaveProfile: " + error);
          });
        // Reset buttonClicked after executing the function
      }
    };
    handleSaveProfile();
    setSaveButtonClicked(false);
  }, [saveButtonClicked]);

  return (
    <div>
      <div className="flex flex flex-col">
        <button
          id="ImportCreateProfileButton"
          type="button"
          onClick={() => setShowModal(true)}
          className="px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
        >
          Add Profile (POC Only)
        </button>
      </div>
      {showModal ? (
        <>
          <div className="modal-container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="modal-content relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="z-50 cursor-pointer"
                      onClick={() => setShowModal(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <h1 className='text-center font-bold '>Create or Import Profile</h1>

                  <div className="w-full py-4 px-12 ">
                    <Stepper
                      activeStep={activeStep}
                      isLastStep={(value) => setIsLastStep(value)}
                      isFirstStep={(value) => setIsFirstStep(value)}
                    >
                      <Step
                        onClick={() => setActiveStep(0)}
                        className="h-4 w-4"
                      >
                        <div className="absolute -bottom-[3.5rem] w-max text-center">
                          <Typography
                            variant="h6"
                            color={activeStep === 0 ? "blue-gray" : "gray"}
                          >
                            Step 1
                          </Typography>
                          <Typography
                            color={activeStep === 0 ? "blue-gray" : "gray"}
                            className="font-normal"
                          >
                            Credentials
                          </Typography>
                        </div>
                      </Step>
                      <Step
                        onClick={() => setActiveStep(1)}
                        className="h-4 w-4"
                      >
                        <div className="absolute -bottom-[3.5rem] w-max text-center">
                          <Typography
                            variant="h6"
                            color={activeStep === 1 ? "blue-gray" : "gray"}
                          >
                            Step 2
                          </Typography>
                          <Typography
                            color={activeStep === 1 ? "blue-gray" : "gray"}
                            className="font-normal"
                          >
                            Initialization
                          </Typography>
                        </div>
                      </Step>
                    </Stepper>
                    <div className="mt-20">
                      {activeStep === 0 && (
                        <div>
                          <GenerateUpdateMnemonicPrivateKey />
                          <div className="mt-10 flex justify-end">
                            <Button
                              onClick={handleNext}
                              disabled={isLastStep}
                              className="px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      )}
                      {activeStep === 1 && (
                        <div>
                          <DeriveEVMAddress />
                          <DeriveHederaAccountDetails />
                          <div className="mt-3 mb-2">
                            <label
                              htmlFor={`profile-type`}
                              className="text-gray-700 block mb-1"
                            >
                              Profile Type
                            </label>
                            <select
                              id={`profile-type`}
                              value={profileType}
                              onChange={handleProfileTypeChange}
                              className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
                            >
                              <option value="Audience">Audience</option>
                              <option value="Creator">Creator</option>
                            </select>
                          </div>
                          <div className="mt-3 mb-2">
                            <label
                              htmlFor={`setup-account-ProfileAlias`}
                              className="text-gray-700 block mb-1"
                            >
                              <span>Profile Name</span>
                            </label>
                            <input
                              type="text"
                              id={`setup-account-ProfileAlias`}
                              value={newProfileAlias}
                              onChange={(e) =>
                                setNewProfileAlias(e.target.value)
                              }
                              className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
                            />
                          </div>
                          <div className="mt-10 flex justify-between">
                            <Button
                              onClick={handlePrev}
                              disabled={isFirstStep}
                              className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                            >
                              Prev
                            </Button>
                            <Button
                              onClick={handleSaveButtonClick}
                              className="px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            >
                              Save Profile
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/*footer*/}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default ImportProfileButtonModal;
