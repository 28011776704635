export const addProfile = async (profile) => {
  return new Promise((resolve, reject) => {
    try {
      const profiles = JSON.parse(localStorage.getItem('profiles')) || [];
      const existingProfile = profiles.find((p) => p.hederaAccountID === profile.hederaAccountID);
      
      if (existingProfile) {
        reject(new Error("Profile with this Hedera Account already exists"));
        alert("Profile with this Hedera Account already exists");
        return;
      }
      
      profiles.push(profile);
      localStorage.setItem('profiles', JSON.stringify(profiles));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfileById = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      const profiles = JSON.parse(localStorage.getItem('profiles')) || [];
      const profile = profiles.find((p) => p.id === id);
      resolve(profile);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfilesAll = async () => {
  return new Promise((resolve, reject) => {
    try {
      const profiles = JSON.parse(localStorage.getItem('profiles')) || [];
      resolve(profiles);
    } catch (error) {
      reject(error);
    }
  });
};