import React, { useState } from "react";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoggedIn(true);
    navigate('/profiles'); // <-- redirect
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Eventful</h2>
        <form className="space-y-4">
          <div>
            <input
              type="email"
              className="w-full border rounded-md px-3 py-2 mt-1 focus:outline-none focus:border-black"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-black text-white py-2 rounded-md hover:bg-black focus:outline-none"
              onClick={handleLogin}
            >
              Sign Up / Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
