import React, { useContext, useEffect, useState } from "react";
import { Mnemonic, PrivateKey } from "@hashgraph/sdk";
import { HDNode as ethersHdNode } from "@ethersproject/hdnode";
import { Wallet as ethersWallet } from "@ethersproject/wallet";
import {
	MnemonicContext,
	PrivateKeyContext,
	EvmAddressContext,
	HederaAccountIDContext,
	HederaAccountBalanceHbarContext,
} from "../contexts/contexts";

const WordInputList = ({ wordCount, wordValues, onChange }) => {
	const handleInputChange = (e, index) => {
		// Store the input value locally without updating the state
		const newValue = e.target.value;
		onChange(newValue, index);
	};

	const handleInputBlur = (e, index) => {
		// When the input field loses focus, update the state with the latest input value
		const newValue = e.target.value;
		onChange(newValue, index);
	};

	return (
		<div className="grid grid-cols-3 gap-4 mt-4">
			{Array(wordCount)
				.fill(null)
				.map((_, index) => (
					<div
						key={`setup-account-mnemonic-word-${index}`}
						className="mb-2"
					>
						<label
							htmlFor={`setup-account-mnemonic-word-${index}`}
							className="text-gray-700 block mb-1"
						>
							<span>Word {index + 1}</span>
						</label>
						<input
							type="text"
							id={`setup-account-mnemonic-word-${index}`}
							className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
							value={wordValues[index]}
							onChange={(e) => handleInputChange(e, index)}
							onBlur={(e) => handleInputBlur(e, index)}
						/>
					</div>
				))}
		</div>
	);
};

export const GenerateUpdateMnemonicPrivateKey = () => {
	const { wordValues, setWordValues } = useContext(MnemonicContext);
	const { privateKeyHex, setPrivateKeyHex } = useContext(PrivateKeyContext);
	const { evmAddress, setEVMAddress } = useContext(EvmAddressContext);
	const { hederaAccountID, setHederaAccountID } = useContext(
		HederaAccountIDContext,
	);
	const handleWordChange = (newValue, index) => {
		// Update the state with the latest input value
		setWordValues((prevValues) => [
			...prevValues.slice(0, index),
			newValue,
			...prevValues.slice(index + 1),
		]);
		// Clear private key when mnemonic is being edited
		setPrivateKeyHex("");
		setHederaAccountID("");
		setEVMAddress("");
	};

	const handleGenerateClick = async () => {
		try {
			const mnemonicString = await Mnemonic.generate12(); // Get string directly
			const wordArray = mnemonicString.toString().split(" "); // Split into words
			setWordValues(wordArray); // Update the state with the words
		} catch (error) {
			console.error("Error generating mnemonic:", error);
			// Display error message to the user
		}
	};

	const handleChangeInMnemonic = async () => {
		try {
			if (wordValues.every((word) => word !== "")) {
				const mnemonicString = wordValues.join(" ").trim();
				// Derive EVM address from mnemonic (same logic as generateMnemonic)
				const hdNodeRoot = ethersHdNode.fromMnemonic(mnemonicString);
				const accountHdPath = "m/44'/60'/0'/0/0";
				const hdNode = hdNodeRoot.derivePath(accountHdPath);
				const privateKey = PrivateKey.fromStringECDSA(
					hdNode.privateKey,
				);
				setPrivateKeyHex(`0x${privateKey.toStringRaw()}`);
				setHederaAccountID("");
				setEVMAddress("");
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Function to handle changes in the private key input
	const handlePrivateKeyChange = (e) => {
		const value = e.target.value;
		setWordValues(Array(12).fill(""));
		setPrivateKeyHex(value);
		setHederaAccountID("");
		setEVMAddress("");
	};

	// Call handleChangeInMnemonic whenever wordValues changes
	useEffect(() => {
		handleChangeInMnemonic();
	}, [wordValues]);

	return (
		<div>
			<div className="flex justify-between mb-5">
				A Hedera Account is required for every new Eventful Profile.
				Generate or enter your existing Mnemonic / Private Key below.
				{/* Save a copy of your Mnemonic / Private Key if you want to */}
				{/* reuse this Profile in the future. Losing this information */}
				{/* will result in permanent loss of this account and any */}
				{/* associated content. */}
			</div>
			<div className="flex justify-end mb-5">
				<button
					onClick={handleGenerateClick}
					className="px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
				>
					Generate
				</button>
			</div>

			<WordInputList
				wordCount={12}
				wordValues={wordValues}
				onChange={handleWordChange}
			/>
			<div className="mt-3 mb-2">
				<label
					htmlFor={`setup-account-privateKey`}
					className="text-gray-700 block mb-1"
				>
					<span>Private Key</span>
				</label>
				<input
					type="text"
					id={`setup-account-privateKey`}
					value={privateKeyHex}
					onChange={handlePrivateKeyChange}
					className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
				/>
			</div>
		</div>
	);
};

export const DeriveEVMAddress = () => {
	const { privateKeyHex, setPrivateKeyHex } = useContext(PrivateKeyContext);
	const { evmAddress, setEVMAddress } = useContext(EvmAddressContext);

	const DerivedEVMAddress = async () => {
		try {
			const wallet = new ethersWallet(privateKeyHex);
			setEVMAddress(wallet.address);
		} catch (e) {
			setEVMAddress("No Valid Private Key or Mnemonic Detected.");
		}
	};
	useEffect(() => {
		DerivedEVMAddress();
	}, [privateKeyHex]);

	return (
		<div>
			<div className="mt-4 ">
				Your new Hedera account needs to be initialized with some HBAR.
				Copy the derived EVM Address below, and get some free Testnet
				HBAR from the faucet{" "}
				<a
					href="https://portal.hedera.com/faucet"
					rel="noopener noreferrer"
					target="_blank"
					style={{ textDecoration: "underline" }}
				>
					here
				</a>
				.
				<div className="mt-3 mb-2">
					<label
						htmlFor={`derivedEVMAddress`}
						className="text-gray-700 block mb-1"
					>
						<span>EVM Address</span>
					</label>
					<input
						type="text"
						id={`setup-account-derivedEVMAddress`}
						value={evmAddress}
						readOnly
						className="bg-gray-100 w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
					/>
				</div>
			</div>
		</div>
	);
};

export const DeriveHederaAccountDetails = ({}) => {
	const { privateKeyHex, setPrivateKeyHex } = useContext(PrivateKeyContext);
	const { hederaAccountID, setHederaAccountID } = useContext(
		HederaAccountIDContext,
	);
	const checkHederaAccountStatus = async () => {
		try {
			const wallet = new ethersWallet(privateKeyHex);
			const evmAddress = wallet.address;
			const accountBalanceFetchApiUrl = `https://testnet.mirrornode.hedera.com/api/v1/balances?account.id=${evmAddress}&limit=1&order=asc`;
			const accountBalanceFetch = await fetch(accountBalanceFetchApiUrl);
			const accountBalanceJson = await accountBalanceFetch.json();
			const accountId = accountBalanceJson.balances[0].account;
			setHederaAccountID(accountId);
		} catch (e) {
			setHederaAccountID("Not Initialized");
		}
	};
	useEffect(() => {
		checkHederaAccountStatus();
	}, [privateKeyHex]);

	return (
		<div>
			<div>
				<div className="mt-4 flex items-center justify-between">
					Click "Check" to see if your new account has been
					successfully initialized. Your Hedera Account ID will be
					shown below if your account is active.
				</div>
				<div className="mb-2 flex justify-end">
					<button
						type="button"
						className="px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
						id="check-hedera-account-id"
						onClick={checkHederaAccountStatus}
					>
						Check
					</button>
				</div>

				<div className="mt-4 grid justify-items-stretch">
					<label
						htmlFor={`setup-account-derivedHederaAccountID`}
						className="text-gray-700 block mb-1"
					>
						<span>Hedera Account ID</span>
					</label>
					<input
						type="text"
						id={`setup-account-derivedHederaAccountID`}
						value={hederaAccountID}
						readOnly
						className="bg-gray-100 w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
					/>
				</div>
			</div>
		</div>
	);
};
