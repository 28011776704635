import React, { useEffect, useState } from "react";

const FormCreateNewEvent = () => {
	const [formData, setFormData] = React.useState({
		title: "Sample Title",
		description: "This is a sample event description.",
		category: "Music",
		date: "2024-04-10",
		time: "10:00",
		duration: "120",
		ticketTypes: [{ type: "Physical, Free Standing", count: 1000 }], // At least one ticket type upon load
	});

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	// Function to handle image upload
	const handleImageUpload = (e) => {
		// Implement image upload logic here
	};

	// Function to handle clearing form fields
	const handleClearFields = () => {
		setFormData({
			title: "",
			description: "",
			category: "",
			date: "",
			time: "",
			duration: "",
			ticketTypes: [{ type: "", count: 1 }], // Reset to at least one ticket type
		});
	};

	// Function to handle adding a new ticket type
	const handleAddTicketType = () => {
		setFormData({
			...formData,
			ticketTypes: [...formData.ticketTypes, { type: "", count: 1 }],
		});
	};

	// Function to handle removing a ticket type
	const handleRemoveTicketType = (index) => {
		if (formData.ticketTypes.length === 1) {
			// If there is only one ticket type, do not remove it
			return;
		}
		const updatedTicketTypes = [...formData.ticketTypes];
		updatedTicketTypes.splice(index, 1);
		setFormData({ ...formData, ticketTypes: updatedTicketTypes });
	};

	// Function to handle updating ticket count for a ticket type
	const handleTicketCountChange = (index, value) => {
		// Ensure the value is at least 1
		const newCount =
			isNaN(value) || parseInt(value) < 1 ? 1 : parseInt(value);
		const updatedTicketTypes = [...formData.ticketTypes];
		updatedTicketTypes[index].count = newCount;
		setFormData({ ...formData, ticketTypes: updatedTicketTypes });
	};

	return (
		<div>
			<div className="mb-6">
				<label
					htmlFor="form-title"
					className="text-gray-700 block mb-2"
				>
					Event Title
				</label>
				<input
					type="text"
					id="title"
					className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mb-2"
					placeholder="Enter event title"
					value={formData.title}
					onChange={handleInputChange}
				/>
				{/* Add event image upload */}
				<label
					htmlFor="form-image"
					className="text-gray-700 block mb-2"
				>
					Event Image
				</label>
				<input
					type="file"
					id="image"
					accept="image/*"
					className="mb-2"
					onChange={handleImageUpload}
				/>
				<label
					htmlFor="form-description"
					className="text-gray-700 block mb-2"
				>
					Event Description
				</label>
				<textarea
					id="description"
					className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mb-2"
					rows="4"
					placeholder="Describe your event here"
					value={formData.description}
					onChange={handleInputChange}
				/>
				<label
					htmlFor="form-category"
					className="text-gray-700 block mb-2"
				>
					Event Category
				</label>
				<select
					id="category"
					className="mb-2 w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black"
					value={formData.category}
					onChange={handleInputChange}
				>
					<option value="Music">Music</option>
					<option value="Arts">Arts</option>
					<option value="Sports">Sports</option>
					{/* Add more categories as needed */}
				</select>
				<label htmlFor="form-date" className="text-gray-700 block mb-2">
					Event Date
				</label>
				<input
					type="date"
					id="date"
					className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mb-2"
					value={formData.date}
					onChange={handleInputChange}
				/>
				<label htmlFor="form-time" className="text-gray-700 block mb-2">
					Event Time
				</label>
				<input
					type="time"
					id="time"
					className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mb-2"
					value={formData.time}
					onChange={handleInputChange}
				/>
				<label
					htmlFor="form-duration"
					className="text-gray-700 block mb-2"
				>
					Duration (in minutes)
				</label>
				<input
					type="number"
					id="duration"
					className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mb-2"
					value={formData.duration}
					onChange={handleInputChange}
				/>
				<div className="mt-3 mb-2 flex justify-between items-center">
					<label
						htmlFor="form-ticket-type"
						className="mt-2 text-gray-700 block mb-2"
					>
						Configure Tickets
					</label>
					<button
						onClick={handleAddTicketType}
						className=" bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded-md"
					>
						Add Ticket Type
					</button>
				</div>

				<div className="">
					{formData.ticketTypes.map((ticket, index) => (
						<div
							key={index}
							className="flex items-center mb-2 justify-between"
						>
							<select
								value={ticket.type}
								onChange={(e) => {
									const updatedTicketTypes = [
										...formData.ticketTypes,
									];
									updatedTicketTypes[index].type =
										e.target.value;
									setFormData({
										...formData,
										ticketTypes: updatedTicketTypes,
									});
								}}
								className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mr-2"
							>
								<option value="PhysicalFreeStandingSeating">
									Physical, Free-Standing or Seating
								</option>
								<option value="PhysicalSeatingPlan">
									Physical, Seating Plan
								</option>
								<option value="Virtual">Virtual</option>
							</select>
							<input
								type="number"
								value={ticket.count}
								onChange={(e) =>
									handleTicketCountChange(
										index,
										e.target.value,
									)
								}
								placeholder="Count"
								className="w-1/4 px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black mr-2"
							/>
							{index === 0 &&
							formData.ticketTypes.length === 1 ? null : (
								// Hide "Remove" button for the last remaining ticket type
								<button
									onClick={() =>
										handleRemoveTicketType(index)
									}
									className="text-red-500 hover:text-red-700"
								>
									Remove
								</button>
							)}
						</div>
					))}
				</div>
			</div>

			<div className="flex justify-between items-center mt-5 mb-5">
				<button
					id="formClearButton"
					onClick={handleClearFields}
					className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
				>
					Clear Fields
				</button>

				<button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
					Create Draft Event
				</button>
			</div>
		</div>
	);
};

export default FormCreateNewEvent;
