import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ImportProfileButtonModal from "../components/ImportProfileButtonModal";
import ProfileList from "../components/ProfileList";

import {
  MnemonicProvider,
  PrivateKeyProvider,
  EvmAddressProvider,
  HederaAccountIDProvider,
  HederaAccountBalanceHbarProvider,
} from "../contexts/contexts";

const PageProfiles = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center p-8">
      <div className="w-full max-w-2xl">
        <div className="flex justify-between items-bottom mb-8">
          <h3 className="text-2xl font-bold mb-3">Your Profiles</h3>
          <MnemonicProvider>
            <PrivateKeyProvider>
              <EvmAddressProvider>
                <HederaAccountIDProvider>
                  <HederaAccountBalanceHbarProvider>
                    <ImportProfileButtonModal />
                  </HederaAccountBalanceHbarProvider>
                </HederaAccountIDProvider>
              </EvmAddressProvider>
            </PrivateKeyProvider>
          </MnemonicProvider>
        </div>
        <div>
          <ProfileList />
        </div>
      </div>
    </div>
  );
};

export default PageProfiles;
