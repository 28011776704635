import { React, useContext } from "react";

import { HDNode as ethersHdNode } from "@ethersproject/hdnode";
import { Wallet as ethersWallet } from "@ethersproject/wallet";

import FormCreateNewEvent from "../components/FormCreateNewEvent";

const PageCreateNewEvent = () => {

  
  return (
    <div className="flex justify-center p-8">
      <div className="w-full max-w-2xl">
        <div className="flex justify-between items-bottom mb-8">
          <span className="text-2xl font-bold">Create Your Event</span>
        </div>
        <FormCreateNewEvent />
      </div>
    </div>
  );
};

export default PageCreateNewEvent;
