import React, { useEffect, useState } from "react";
import { getProfilesAll } from "../backend/LocalStorageProfiles";

const ProfileList = () => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profilesData = await getProfilesAll();
        setProfiles(profilesData);
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };
    fetchProfiles();
  });

  return (
    <div>
      {profiles.map((profile) => (
        <a
          key={profile.hederaAccountID}
          href={`/events?useProfile=${profile.hederaAccountID}`}
          className={`block ${
            profile.profileType === "Creator"
              ? "bg-blue-500 text-white"
              : "bg-white text-black"
          } rounded-lg shadow-sm p-4 mb-4 hover:shadow-lg`}
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="font-bold text-lg mb-2">
                {profile.profileType === "Creator" ? "Creator" : "Audience"}
              </div>
              <div className="font-bold text-lg mb-2">{profile.alias}</div>
              <div
                className={`${profile.profileType === "Creator" ? "text-white" : "text-black"} mb-2 text-sm `}
              >
                {profile.hederaAccountID}
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <a href="#">
                <button
                  className={`px-4 py-2 rounded-md hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
                    profile.profileType === "Creator"
                      ? "bg-white text-black"
                      : "bg-black text-white"
                  }`}
                >
                  Manage
                </button>
              </a>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default ProfileList;
