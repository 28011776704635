import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageEvents = () => {
  const queryString = window.location.search;
  // Define your base URL
  const baseUrl = "/create-new-event";
  // Concatenate the base URL with the query string
  const hrefValue = `${baseUrl}${queryString}`;

  return (
    <div>
      <div className="flex justify-center p-8">
        <div className="w-full max-w-2xl">
          <div className="flex justify-between items-bottom mb-8">
            <span className="text-2xl font-bold">Creator Panel</span>
            <h3 className="mb-3 px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
              Profile Name
            </h3>
          </div>
          <div className="flex justify-between items-bottom mb-8">
            <div>
              <h3 className="text-lg font-bold mb-3">Upcoming Events</h3>
              <h3 className="text-lg font-bold mb-3">Draft Events</h3>
              <h3 className="text-lg font-bold mb-3">Past Events</h3>
            </div>
            <div>
              <a href={`${hrefValue}`}>
                <h3 className="px-4 py-2 bg-black text-white rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                  Create New Event
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center p-8">
        <div className="w-full max-w-2xl">
          <div className="flex justify-between items-bottom mb-8">
            <span className="text-2xl font-bold">Audience Panel</span>
          </div>
          <h3 className="text-lg font-bold mb-3">Browse Events</h3>
          <h3 className="text-lg font-bold mb-3">Upcoming Events</h3>
          <h3 className="text-lg font-bold mb-3">Saved Events</h3>
          <h3 className="text-lg font-bold mb-3">Past Events</h3>
        </div>
      </div>
    </div>
  );
};

export default PageEvents;
